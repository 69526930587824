exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-sanity-blog-post-slug-current-js": () => import("./../../../src/pages/blog/{SanityBlogPost.slug__current}.js" /* webpackChunkName: "component---src-pages-blog-sanity-blog-post-slug-current-js" */),
  "component---src-pages-book-an-appointment-js": () => import("./../../../src/pages/book-an-appointment.js" /* webpackChunkName: "component---src-pages-book-an-appointment-js" */),
  "component---src-pages-book-piercing-appointment-js": () => import("./../../../src/pages/book-piercing-appointment.js" /* webpackChunkName: "component---src-pages-book-piercing-appointment-js" */),
  "component---src-pages-book-tattoo-appointment-js": () => import("./../../../src/pages/book-tattoo-appointment.js" /* webpackChunkName: "component---src-pages-book-tattoo-appointment-js" */),
  "component---src-pages-buy-gift-card-js": () => import("./../../../src/pages/buy-gift-card.js" /* webpackChunkName: "component---src-pages-buy-gift-card-js" */),
  "component---src-pages-sanity-locations-pages-slug-current-js": () => import("./../../../src/pages/{SanityLocationsPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-locations-pages-slug-current-js" */),
  "component---src-pages-sanity-meet-the-team-slug-current-js": () => import("./../../../src/pages/{SanityMeetTheTeam.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-meet-the-team-slug-current-js" */),
  "component---src-pages-sanity-pages-slug-current-js": () => import("./../../../src/pages/{SanityPages.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-pages-slug-current-js" */)
}

